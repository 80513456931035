<template>
  <v-dialog v-model="showDialog" persistent width="540px">
    <template v-slot:activator="{ on:dialog }">
      <v-btn
        v-on="dialog"
        v-if="isBtn"
        v-bind="frBtnProps"
        :class="`${styles}`"
        :disabled="valid"
        :loading="loading"
        :icon="isIcon"
        :tile="tile"
        :block="block"
        :max-height="maxHeight"
        :height="height"
        :x-small="xSmall"
        :fab="fab"
      >
        <v-icon
          :small="iSmall"
          v-if="wIcon"
          :left="iLeft"
          :right="iRight"
          :color="iColor"
          v-show="show"
        >
          {{ btnIcon }}
        </v-icon>
        {{ btnTitle }}
      </v-btn>

      <v-tooltip bottom v-else-if="isTooltip && isIcon">
        <template v-slot:activator="{ on: tooltip }">
          <v-icon
            v-on="{...dialog, ...tooltip}"
            small
            :left="iLeft"
            :right="iRight"
            :color="iColor"
            :class="`${styles}`"
            v-show="show"
            :disabled="valid"
            v-if="!loading"
          >
            {{ btnIcon }}
          </v-icon>
          <slot name="loading"></slot>
        </template>
        <span>
          {{ tpText }}
          <span style="font-size: 12px; color: #aaaaaa">
            {{ tsText }}
          </span>
        </span>
      </v-tooltip>
      <v-icon
        v-on="dialog"
        v-else-if="isIcon"
        small
        :left="iLeft"
        :right="iRight"
        :color="iColor"
      >
        {{ btnIcon }}
      </v-icon>
    </template>
    <v-card>
      <v-card-title class="headline secondary t-bw-secondary--text">{{ title }}</v-card-title>
      <v-card-text class="pa-6">
        <slot name="dialog-content">
          <div class="delete-confirm-content">
            <div v-if="hasCustomContent" v-html="dialogText"></div>
            <div v-else>{{ dialogText }}</div>
          </div>
        </slot>
      </v-card-text>
      <v-card-actions class="pa-6">
        <v-spacer></v-spacer>
        <v-btn small text color="neuter" @click="closeDialog()">
          {{ $t("common.buttons.cancel") }}
        </v-btn>
        <v-btn small v-bind="frBtnProps" @click="confirmDialog()">
          {{ $t("common.buttons.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  import i18n from "@/plugins/i18n"

  export default {
    name: "ConfirmDialog",
    props: {
      item: {
        type: Object,
        required: false,
      },
      items: {
        type: Array,
        required: false,
      },
      show: {
        type: Boolean,
        required: false,
        default: true,
      },
      btnIcon: {
        type: String,
        required: false,
      },
      iLeft: {
        type: Boolean,
        default: false,
      },
      iRight: {
        type: Boolean,
        default: false,
      },
      iColor: {
        type: String,
        default: "neuter",
      },
      isIcon: {
        type: Boolean,
        required: false,
      },
      wIcon: {
        type: Boolean,
        required: false,
      },
      text: {
        type: Boolean,
        required: false,
        default: true
      },
      isBtn: {
        type: Boolean,
        required: true
      },
      tpText: {
        type: String,
        required: false
      },
      tsText: {
        type: String,
        required: false
      },
      isTooltip: {
        type: Boolean,
        required: false
      },
      btnTitle: {
        type: String,
        require: false,
      },
      frBtnProps: {
        type: Object,
        required: false,
        default: () => ({
          small: true,
          color: "primary",
          textColor: "t-bw-primary--text",
          elevation: 0,
          text: false
        })
      },
      styles: {
        type: String,
        required: false
      },
      title: {
        type: String,
        required: false,
        default: i18n.t("common.titles.titleGenericDialog"),
      },
      dialogText: {
        type: String,
        required: false,
        default: i18n.t("common.titles.descriptionGenericDialog"),
      },
      action: {
        type: String,
        required: true,
      },
      loading: {
        type: Boolean,
        required: false,
      },
      valid: {
        type: Boolean,
        required: false,
      },
      tile: {
        type: Boolean,
        required: false,
      },
      xSmall: {
        type: Boolean,
        required: false,
      },
      iSmall: {
        type: Boolean,
        required: false,
      },
      small: {
        type: Boolean,
        required: false,
      },
      elevation: {
        type: Number,
        required: false,
      },
      block: {
        type: Boolean,
        required: false,
      },
      fab: {
        type: Boolean,
        required: false,
      },
      maxHeight: {
        type: String,
        required: false,
      },
      height: {
        type: String,
        required: false,
      },
      hasCustomContent: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        showDialog: false,
      }
    },

    methods: {
      openDialog() {
        this.showDialog = true
      },
      closeDialog() {
        this.showDialog = false
      },
      confirmDialog() {
        console.log(this.action)
        this.$root.$emit(this.action, this.item || this.items)
        this.closeDialog()
      },
    },
  }
</script>

<style scoped>
.delete-confirm-content {
  white-space: pre-line;
  line-height: 1.6;
}

/* Estilizar bullets y sub-bullets */
.delete-confirm-content ul {
  list-style: none;
  padding-left: 0;
  margin: 1em 0;
}

.delete-confirm-content li {
  padding-left: 1.5em;
  position: relative;
  margin-bottom: 0.5em;
}

.delete-confirm-content li:before {
  content: "•";
  position: absolute;
  left: 0.5em;
}

.delete-confirm-content li li:before {
  content: "-";
}

.delete-confirm-content .warning-text {
  color: #ff5252;
  font-weight: 500;
  margin: 1em 0;
}

.delete-confirm-content .suggestion-text {
  color: #4caf50;
  font-style: italic;
  margin-top: 1em;
}
</style>
