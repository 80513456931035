import { render, staticRenderFns } from "./AddOrEditComponent.vue?vue&type=template&id=08211f7c&scoped=true"
import script from "./AddOrEditComponent.vue?vue&type=script&lang=js"
export * from "./AddOrEditComponent.vue?vue&type=script&lang=js"
import style0 from "./AddOrEditComponent.vue?vue&type=style&index=0&id=08211f7c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08211f7c",
  null
  
)

export default component.exports