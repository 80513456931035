
  import { Component, Vue, Prop } from 'vue-property-decorator';
  import ReadMore from '@/components/ReadMoreComponent.vue';
  import ShowMoreComponent from '@/components/ShowMoreComponent.vue'
  import LanguageService from '@/services/LanguajeService';
  import DocumentsService from '@/services/DocumentsService';
  import printJs from 'print-js';
  import i18n from "@/plugins/i18n"

  @Component({
    components: {
      ReadMore,
      ShowMoreComponent
    },
  })
  export default class VersionsDialogComponent extends Vue {
    @Prop({ type: Boolean, default: false }) showVersionsDialog!: boolean;
    @Prop({ type: Array, default: () => [] }) versions!: any[];

    loadingDownloadDocument: Record<number, boolean> = {};
    loadingPrintDocument: Record<number, boolean> = {};

    get tableHeaders() {
      return [
        { text: i18n.t('documents.fields.version'), value: 'version' },
        { text: i18n.t('documents.fields.type'), value: 'type_name' },
        { text: i18n.t('documents.fields.description'), value: 'description_name' },
        { text: i18n.t('documents.fields.document_date'), value: 'emission' },
        { text: i18n.t('documents.fields.expiration_date'), value: 'validade' },
        { text: i18n.t('documents.fields.location'), value: 'storage_devices' },
        { text: i18n.t('documents.fields.season'), value: 'season' },
        { text: i18n.t('documents.fields.observations'), value: 'observations' },
        { text: i18n.t('documents.fields.public'), value: 'isPublic' },
        { text: i18n.t('common.fields.action'), value: 'actions' },
      ];
    }

    async downloadDoc(isVersion: boolean, item: any): Promise<void> {
      try {
        if (isVersion && !item.currentVersion) {
          const response = await DocumentsService.downloadDocumentFormVersion(item.id, item.version);
          const FileSaver = require('file-saver');
          const blob = new Blob([response.data], {
            type: 'application/zip',
          });
          FileSaver.saveAs(blob, `evidences_v${item.version}.zip`);
        } else {
          const response = await DocumentsService.downloadDocument(item.id);
          const FileSaver = require('file-saver');
          const blob = new Blob([response.data], {
            type: 'application/zip',
          });
          FileSaver.saveAs(blob, 'evidences.zip');
        }
      } catch (error) {
        this.$toast.info(i18n.t('documents.notification.evidencesCero').toString(), {
          icon: 'mdi-information-slab-circle',
          queueable: true
        });
      }
    }

    async downloadDocVersion(isVersion: boolean, item: any, index: number): Promise<void> {
      this.$set(this.loadingDownloadDocument, `${index}`, true);
      try {
        await this.downloadDoc(isVersion, item);
      } finally {
        this.$set(this.loadingDownloadDocument, `${index}`, false);
      }
    }

    async printDoc(file: string, extension: string): Promise<void> {
      if (['jpg', 'png', 'jpeg'].includes(extension)) {
        await printJs({
          printable: file,
          type: 'image',
          imageStyle: 'display:block;margin-left:auto;margin-right:auto;height:auto;width:100%',
          documentTitle: 'Documents',
        });
      } else {
        await printJs({
          printable: file,
          type: 'pdf',
          documentTitle: 'Documents',
        });
      }
    }

    async printDocVersion(file: string, extension: string, index: number): Promise<void> {
      this.$set(this.loadingPrintDocument, `${index}`, true);
      try {
        await this.printDoc(file, extension);
      } finally {
        this.$set(this.loadingPrintDocument, `${index}`, false);
      }
    }

    closeVersionsDialog() {
      this.$emit('close');
    }

    translate(key: []): string {
      return LanguageService.getKey3(key);
    }
  }
